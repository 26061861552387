import React from 'react'; // First import (correct)
import { useState } from 'react';
import withScrollToTop from './ScrollToTop';
import Contact from './Contact';
import '../About.css';
import teamImage1 from '../assets/team1.jpg'; // Replace with your actual image paths
import teamImage2 from '../assets/team2.jpg';
import teamImage3 from '../assets/team3.JPG';


const About = () => {
  const [showContactPopup, setShowContactPopup] = useState(false); // State to control the popup

  const toggleContactPopup = () => {
    setShowContactPopup(!showContactPopup); // Toggle the popup state
  };
  return (
    <div className="about-container">
      {/* Hero Section */}
      <section className="hero-section">
        <h1>Innovative Solutions for a Digital World</h1>
        <p>
        At NestTech Solutions, we specialize in creating innovative web and mobile solutions tailored to bring your business ideas to life. Our team of skilled developers is committed to delivering high-quality digital products that help you succeed in the ever-evolving tech landscape.
        </p>
      </section>

      {/* Mission & Vision Section */}
      <section className="mission-vision-section">
        <div className="mission">
          <h2>Our Mission</h2>
          <p>To empower businesses with cutting-edge technology solutions that drive growth, efficiency, and customer satisfaction.</p>
        </div>
        <div className="vision">
          <h2>Our Vision</h2>
          <p>To be a global leader in innovative technology solutions that shape the future of digital transformation.</p>
        </div>
      </section>

      {/* Values Section */}
      <section className="values-section">
        <h2>Our Core Values</h2>
        <div className="values">
          <div className="value">
            <i className="fas fa-lightbulb"></i>
            <h3>Innovation</h3>
            <p>We are committed to constantly exploring new ideas and technologies.</p>
          </div>
          <div className="value">
            <i className="fas fa-handshake"></i>
            <h3>Integrity</h3>
            <p>We maintain the highest standards of professionalism and ethical practices.</p>
          </div>
          <div className="value">
            <i className="fas fa-users"></i>
            <h3>Collaboration</h3>
            <p>We believe in working closely with our clients to deliver solutions that truly meet their needs.</p>
          </div>
        </div>
      </section>

      {/* Team Section */}
      <div className="team-container">
      <h1>Meet Our Team</h1>
      <div className="team-member">
        <img src={teamImage1} alt="Team Member 1" />
        <h3>Carter</h3>
        <p><b>Full Stack Developer</b></p>
        <p className='p-description'>Carter is a versatile full-stack developer, proficient in both frontend and backend technologies, with a passion for building scalable, efficient, and intuitive web applications.</p>
      </div>
      <div className="team-member">
        <img src={teamImage2} alt="Team Member 2" />
        <h3>Amelia</h3>
        <p><b>Software Developer</b></p>
        <p className='p-description'>Amelia is a dedicated software engineer with a strong focus on building user-centric applications, skilled in modern web technologies and backend development.</p>
      </div>
      <div className="team-member">
        <img src={teamImage3} alt="Team Member 3" />
        <h3>Barkat Kamran</h3>
        <p><b>Software Developer</b></p>
        <p className='p-description'>Barkat Kamran is a software engineer with many hands-on experiences in full-stack development, cloud technologies, and scalable web applications.</p>
      </div>
    </div>
    

      {/* Journey Section */}
      <section className="journey-section">
        <h2>Our Journey</h2>
        <ul className="timeline">
          <li>
            <h3>Founded</h3>
            <p>In <b>2020</b>, we embarked on our journey with a clear vision: to transform the landscape of digital solutions. Our founders recognized the need for innovative technology 
            that not only meets the demands of businesses but also enhances user experiences. From the beginning, our mission has been to empower organizations by providing them with 
            state-of-the-art digital tools that drive success..</p>
            </li>
          
          <li>
            <h3>First Major Project</h3>
            <p>By 2021, we achieved a significant milestone by launching our first major web application for a global client. This project not only marked our entry into the 
              competitive digital marketplace but also showcased our commitment to quality, creativity, and reliability. The successful execution of this project allowed us 
              to build strong relationships with our clients and laid the groundwork for future endeavors.</p>
          </li>
          <li>
          <h3>Expanding Our Services</h3>
              <p>In 2023, we took a bold step forward by expanding our services to include mobile app development. Recognizing the increasing demand for mobile solutions, we aimed 
                to provide our clients with a comprehensive suite of digital services that caters to their diverse needs. This expansion reflects our dedication to 
                staying ahead of industry trends and continuously enhancing our capabilities to better serve our clients.</p>
          </li>
          <li>
            <h3>Vision for the Future</h3>
            <p>As we look ahead, we remain committed to innovation, excellence, and customer satisfaction. Our journey is just beginning, and we are excited about the opportunities 
              that lie ahead. We believe in fostering lasting partnerships with our clients and are eager to contribute to their success stories as we continue to grow and 
              evolve in the ever-changing digital landscape.</p>
          </li>
        </ul>
      </section>

      {/* Why Choose Us Section */}
      <section className="why-choose-us">
        <h2>Why Choose Us?</h2>
        <p>With a focus on innovation, quality, and customer satisfaction, NestTech Solutions has a proven track record of delivering robust digital solutions.</p>
      </section>

      {/* Call to Action */}
      <section className="cta-section">
        <h2>Want to Learn More?</h2>
        <p>Contact us today and let’s turn your vision into reality.</p>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault(); // Prevent default anchor behavior
            toggleContactPopup(); // Open the contact form
          }}
          className="contactbutton"
        >
          Get In Touch!
        </a>

        {/* Render Contact Form Popup */}
      {showContactPopup && <Contact toggleContactPopup={toggleContactPopup} />}
      </section>
    </div>
  );
};

export default withScrollToTop(About);
