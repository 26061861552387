import React, { useState } from 'react';
import withScrollToTop from './ScrollToTop';
import '../App.css'; // External CSS for styling
import logo from '../assets/right-side.png'; // Import your logo
import type1Image from '../assets/type1.jpg';
import profileImage from '../assets/profile-picture.jpg';
import Contact from './Contact'; // Ensure Contact is imported

function Home() {
  const [showContactPopup, setShowContactPopup] = useState(false); // State to control the popup

  const toggleContactPopup = () => {
    setShowContactPopup(!showContactPopup); // Toggle the popup state
  };

  return (
    <div>
      {/* Home Page Content */}
      <div className="home-content">
        <h1>Need Custom Web Development? <br />Partner with a Professional Today!</h1>
        <p>With extensive experience in delivering high-quality, scalable web applications, 
           I focus on building secure, user-friendly solutions that help businesses succeed 
           online. Whether you need to improve your website's performance, develop a new feature-rich 
           application, or require ongoing maintenance, I’m ready to bring your vision to life.</p>
        <p>From tackling complex problems to providing continuous support, I ensure your software meets modern standards and empowers your business to grow.</p>
        
        {/* Button to open the contact form */}
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault(); // Prevent default anchor behavior
            toggleContactPopup(); // Open the contact form
          }}
          className="contactbutton"
        >
          Let's Talk About Your Project!
        </a>

         {/* Add Logo Here */}
      <div className="logo-container">
        <img src={logo} alt="Nestech Solutions Logo" className="animated-logo" />
      </div>

        <h2>How Can We Assist You With?</h2>
      </div>

      {/* Render Contact Form Popup */}
      {showContactPopup && <Contact toggleContactPopup={toggleContactPopup} />}

      <div className="services-container">
        <div className="service-item">
          <div className="icon ruby"></div>
          <h3>End-to-End Web Development Solutions</h3>
          <p>
            Looking for a website that not only looks great but performs flawlessly? Our team crafts 
            dynamic, high-performance web applications tailored to your vision. From sleek, user-friendly 
            frontends to powerful, secure backends, we deliver full-stack solutions designed to elevate 
            your business and drive results.
          </p>
        </div>
        <div className="service-item">
          <div className="icon audit"></div>
          <h3>Code Review & Optimization</h3>
          <p>
            Is your codebase in need of refinement? Our experts will review your current setup, 
            identifying areas for performance improvement, better security practices, and optimizing 
            for maintainability and scalability.
          </p>
        </div>
        <div className="service-item">
          <div className="icon support"></div>
          <h3>Ongoing Support & Maintenance</h3>
          <p>
            Keeping your web app running smoothly is essential. We offer long-term support and 
            maintenance services, ensuring your application stays updated and secure, 
            while minimizing downtime.
          </p>
        </div>
        <div className="service-item">
          <div className="icon upgrade"></div>
          <h3>Technology Upgrades & Migrations</h3>
          <p>
            As technology evolves, so should your application. We handle seamless upgrades and 
            migrations to newer versions, ensuring your system is always up-to-date with the latest 
            features and security standards.
          </p>
        </div>
        <div className="service-item">
          <div className="icon team"></div>
          <h3>Expert Team Expansion</h3>
          <p>
            Need to boost your team for a project? We provide senior developers to augment your team, 
            adding expertise exactly where you need it. Whether it’s short-term or long-term, we’ve got you covered.
          </p>
        </div>
        <div className="service-item">
          <div className="icon junior"></div>
          <h3>Empower Your Team with Training</h3>
          <p>
            We believe in empowering teams to build better solutions. Our training services help your 
            junior developers upskill quickly, enabling your in-house team to handle more complex 
            challenges with confidence.
          </p>
        </div>
      </div>
      <div className="container">
        <div className="content">
          <h1 className="title">
            We are a Dedicated Team of Full-Stack Development Experts
          </h1>
          <p className="description">
            Our experienced team of full-stack developers spans across the globe, ensuring you have round-the-clock access to top-tier talent. With developers located across the Canada (USA, Canada, Australia, Europe), 
            West Asia, and Africa, we align seamlessly with your time zone to maximize efficiency and productivity.
          </p>
          <p className="description">
            Unlike others, we don’t rely on freelancers or third-party contractors — we commit fully to your project with our in-house specialists, ensuring consistent quality and 
            dedication from start to finish. By integrating directly into your product team, we quickly deliver results, adding immediate value with high-quality code and proven 
            development practices. Our clients choose us for our expertise, but they stay because we help transform their business with innovative, scalable solutions.
          </p>
        </div>
        <div className="image-section">
          <img src={type1Image} alt="Planning Diagram" className="image" />
        </div>
      </div>

      <div className="testimonial-container">
        <p className="testimonial-quote"> 
          "It's not typical to find a team that not only delivers high-quality full-stack web solutions but also improves the performance, scalability, 
          and user experience of your application with every update."
        </p>
        <div className="testimonial-author">
          <img src={profileImage} alt="Author" className="author-image" />
          <div>
            <p className="author-name">Barkat Kamran</p>
            <p className="author-title">Lead Web Developer</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withScrollToTop(Home);
