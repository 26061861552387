// src/components/ProfessionalBanner.js
import React from 'react';
import '../Banner.css';

const ProfessionalBanner = () => {
  return (
    <div className="professional-banner">
      <div className="fireball"></div>
      <div className="dynamic-text">Innovative Solutions for Digital Success</div>
      <div className="banner-overlay">
        <div className="banner-text">
          <p className="slide-in">
            Unlock the power of technology with our cutting-edge web and mobile development services.
          </p>
        </div>
      </div>
      <div className="scroll-down-indicator">
        <span>Scroll Down</span>
        <div className="indicator-arrow"></div>
      </div>
    </div>
  );
};

export default ProfessionalBanner;
