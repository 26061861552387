import React from 'react';
import { useState } from 'react';
import withScrollToTop from './ScrollToTop';
import Contact from './Contact';
import '../CaseStudies.css'; // Updated CSS included

function CaseStudies() {
  const [showContactPopup, setShowContactPopup] = useState(false); // State to control the popup

  const toggleContactPopup = () => {
    setShowContactPopup(!showContactPopup); // Toggle the popup state
  };

  return (
    <div className="casestudies">
      {/* Hero Section - Styled like the screenshot */}
      <section className="hero-section">
      <h1>
      At Nestech Solutions, we empower<br />
    <span className="highlight">businesses like yours to develop</span> transformative solutions
</h1>

        
        <p>
        Whether you’re launching your first idea or have years of experience in your industry, Nestech Solutions is here to guide you in achieving successful outcomes for your projects.
        </p>
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault(); // Prevent default anchor behavior
            toggleContactPopup(); // Open the contact form
          }}
          className="cta-button"
        >
          Let’s get Started!
        </a>

        {/* Render Contact Form Popup */}
      {showContactPopup && <Contact toggleContactPopup={toggleContactPopup} />}
      </section>

    </div>
  );
}

export default withScrollToTop(CaseStudies);
