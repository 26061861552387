// src/components/Services.js
import React, { useEffect } from 'react';
import withScrollToTop from './ScrollToTop';
import '../Services.css';
import webDevelopmentImg from '../assets/web-dev.jpg';  // Add proper paths to your images
import mobileAppImg from '../assets/mobile-app.jpg';
import cloudSolutionsImg from '../assets/cloud-solutions.jpg';
import ecommerceImg from '../assets/e-commerce.jpg';
import uiuxImg from '../assets/ui-ux.jpg';
import ProfessionalBanner from './Banner';  // Use the new professional banner

const Services = () => {
  useEffect(() => {
    // Trigger animations on scroll
    const handleScroll = () => {
      const elements = document.querySelectorAll('.animation-container');
      const windowHeight = window.innerHeight;

      elements.forEach((el) => {
        const rect = el.getBoundingClientRect();
        if (rect.top < windowHeight - 100) {
          el.classList.add('animate');  // Add animation class when element is in view
        }
      });
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div>
      {/* Professional Animated Banner */}
      <ProfessionalBanner />  {/* Add the new banner at the top */}

      {/* Services Section */}
      <div className="services-section">
        <div className="services-header">
          <h1>Our Services</h1>
          <p>
            We offer a wide range of services that combine innovative technology with tailored strategies to help businesses grow and succeed.
            Whether you need a new website, mobile app, or cloud solutions, our expertise will bring your vision to life.
          </p>
        </div>

        <div className="services-grid">
          <div className="service-card">
            <img src={webDevelopmentImg} alt="Web Development" />
            <h3>Web Development</h3>
            <p>We create fully responsive, dynamic, and SEO-friendly websites that help your business grow online.</p>
          </div>

          <div className="service-card">
            <img src={mobileAppImg} alt="Mobile App Development" />
            <h3>Mobile App Development</h3>
            <p>We develop engaging, intuitive mobile apps for iOS and Android that your users will love.</p>
          </div>

          <div className="service-card">
            <img src={cloudSolutionsImg} alt="Cloud Solutions" />
            <h3>Cloud Solutions</h3>
            <p>Our scalable and secure cloud solutions help businesses optimize their operations with efficiency.</p>
          </div>

          <div className="service-card">
            <img src={ecommerceImg} alt="E-Commerce Development" />
            <h3>E-Commerce Solutions</h3>
            <p>We build custom e-commerce platforms that provide seamless shopping experiences for your customers.</p>
          </div>

          <div className="service-card">
            <img src={uiuxImg} alt="UI/UX Design" />
            <h3>UI/UX Design</h3>
            <p>Our design experts craft user interfaces that are not only visually appealing but also intuitive and user-friendly.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withScrollToTop(Services);
