import React from 'react';

function Blog() {
  return (
    <a 
          href="https://barkatkamran.com/posts.php"  
          target="_blank" 
          rel="noopener noreferrer"
          className="blog-link"
        >
          Visit My Blog
        </a>
  );
}

export default Blog;
