import React, { useState } from 'react';
import logoImage from '../assets/nest.png'; // Your logo image
import '../Footer.css'; // Your footer CSS
import Contact from './Contact'; // Import Contact component

const Footer = () => {
  const [isContactFormVisible, setContactFormVisible] = useState(false);

  // Function to toggle the visibility of the contact form
  const toggleContactPopup = (e) => {
    e.preventDefault(); // Prevent link navigation
    setContactFormVisible(!isContactFormVisible); // Toggle visibility
  };

  return (
    <>
      <footer className="footer">
        <div className="footer-container">
          <div className="footer-logo">
            <img src={logoImage} alt="Nestech Solutions Logo" />
            <span>Nestech Solutions</span>
          </div>
          
          <div className="footer-links">
            <div>
              <h4>Services</h4>
              <ul>
                <li><a href="/about">About</a></li>
                <li><a href="/services">Services</a></li>
                <li><a href="https://barkatkamran.com">Portfolio</a></li>
              </ul>
            </div>
            <div>
              <h4>Our Company</h4>
              <ul>
                <li><a href="/casestudies">Case Studies</a></li>
                <li><a href="https://barkatkamran.com/posts.php">Blog</a></li>
                <li><a href="#" onClick={toggleContactPopup}>Contact</a></li> {/* Link to open Contact form */}
              </ul>
            </div>
            <div>
              <h4>Contact</h4>
              <ul>
                <li>Canada: +12368867898</li>
                <li>Privacy Policy</li>
              </ul>
            </div>
          </div>

          <div className="footer-info">
            <p>© 2024 Nestech Solutions.</p>
            <p>We create custom websites and mobile applications that transform your ideas into reality.</p>
          </div>
        </div>
      </footer>

      {/* Conditionally render the Contact component */}
      {isContactFormVisible && (
        <Contact toggleContactPopup={toggleContactPopup} /> 
      )}
    </>
  );
}

export default Footer;
