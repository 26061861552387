import React, { useState, useEffect, useRef } from 'react';
import { Link, BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Services from './Pages/Services';
import Portfolio from './Pages/Portfolio';
import CaseStudies from './Pages/CaseStudies';
import Blog from './Pages/Blog';
import Contact from './Pages/Contact';
import QuoteRequest from './Pages/QuoteRequest'; // Import the new component
import './App.css';
import logo from './assets/nest.png'; // Update the path to your logo image
import Footer from './Pages/Footer';

function App() {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [showContactPopup, setShowContactPopup] = useState(false);
  const [showQuoteRequest, setShowQuoteRequest] = useState(false); // State for quote request
  const [isDropdownOpen, setDropdownOpen] = useState({ services: false, portfolio: false });
  const servicesRef = useRef(null);
  const portfolioRef = useRef(null);


  const [isActive, setIsActive] = useState(false);
  const handleQuoteRequest = (e) => {
    e.preventDefault();
    setIsActive(true); // Set the button as active
    toggleQuoteRequest(); // Call your function to toggle the quote request popup
    toggleMobileMenu(); // Call your function to toggle the mobile menu
  }
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleContactPopup = () => {
    setShowContactPopup(!showContactPopup);
  };

  const toggleQuoteRequest = () => {
    setShowQuoteRequest(!showQuoteRequest);
  };

  const toggleDropdown = (dropdown) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [dropdown]: !prevState[dropdown],
    }));
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        servicesRef.current &&
        !servicesRef.current.contains(event.target) &&
        portfolioRef.current &&
        !portfolioRef.current.contains(event.target)
      ) {
        setDropdownOpen({ services: false, portfolio: false });
      }
    };

    window.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <Router>
      <nav className="navbar">
        <img src={logo} alt="Nestech Solutions Logo" className="logo" />
        <div className="mobile-menu" onClick={toggleMobileMenu}>
          ☰
        </div>
        <ul className={`nav-links ${isMobileMenuOpen ? 'active' : ''}`}>
          <li><Link to="/" onClick={toggleMobileMenu}>Home</Link></li>
          <li><Link to="/about" onClick={toggleMobileMenu}>About</Link></li>

          {/* Dropdown for Services & Case Studies */}
          <li className="dropdown" ref={servicesRef}>
            <span className="dropdown-toggle" onClick={(e) => { e.stopPropagation(); toggleDropdown('services'); }}>
              Solutions ▾
            </span>
            {isDropdownOpen.services && (
              <ul className="dropdown-menu">
                <li><Link to="/services" onClick={toggleMobileMenu}>Services</Link></li>
                <li><Link to="/casestudies" onClick={toggleMobileMenu}>Case Studies</Link></li>
              </ul>
            )}
          </li>

          {/* Dropdown for Portfolio & Blog */}
          <li className="dropdown" ref={portfolioRef}>
            <span className="dropdown-toggle" onClick={(e) => { e.stopPropagation(); toggleDropdown('portfolio'); }}>
              Creative Hub ▾
            </span>
            {isDropdownOpen.portfolio && (
              <ul className="dropdown-menu">
                <li>
                  <a
                    href="https://barkatkamran.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={toggleMobileMenu}
                  >
                    Portfolio
                  </a>
                </li>
                <li>
                  <a
                    href="https://barkatkamran.com/posts.php"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={toggleMobileMenu}
                  >
                    Blog
                  </a>
                </li>
              </ul>
            )}
          </li>

          {/* Contact link */}
          <li>
            <Link to="#" onClick={(e) => { e.preventDefault(); toggleContactPopup(); toggleMobileMenu(); }}>
              Contact
            </Link>
          </li>

          {/* Request Free Quote button */}
          <li>
          <Link
      to="#"
      onClick={(e) => {
        e.preventDefault();
        toggleQuoteRequest();
        toggleMobileMenu();
      }}
      className="quote-button" // Use the active style all the time
    >
      Request Free Quote
    </Link>
          </li>
        </ul>
      </nav>

      {showContactPopup && <Contact toggleContactPopup={toggleContactPopup} />}
      {showQuoteRequest && <QuoteRequest toggleQuoteRequest={toggleQuoteRequest} />} {/* Include QuoteRequest here */}

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/casestudies" element={<CaseStudies />} />
        <Route path="/blog" element={<Blog />} />
      </Routes>

      <Footer />
    </Router>
  );
}

export default App;
