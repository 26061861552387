import React, { useState } from 'react';
import '../App.css'; // Add this line to import CSS

const QuoteRequest = ({ toggleQuoteRequest }) => {
  const [step, setStep] = useState(0);
  const [websiteType, setWebsiteType] = useState('');
  const [Needs, setNeeds] = useState('');
  const [budget, setBudget] = useState('');
  const [timeline, setTimeline] = useState('');
  const [contactInfo, setContactInfo] = useState({
    fullName: '',
    email: '',
    mobile: '',
  });

  const handleNextStep = (e) => {
    e.preventDefault(); // Prevent form submission
    if (step < 3) {
      setStep(step + 1);
    } else {
      handleSubmit(e); // Call submit function when on the last step
    }
  };

  const handleBackStep = (e) => {
    e.preventDefault(); // Prevent default behavior
    if (step > 0) {
      setStep(step - 1); // Go back to the previous step
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Collecting the quote request data
    const data = {
      websiteType,
      Needs,
      budget,
      timeline,
      contactInfo,
    };

    try {
      // Sending data to the PHP backend
      const response = await fetch('https://nestechsolutions.barkatkamran.com/quote-request.php', { // Replace with your actual domain and path
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        alert("Quote request submitted successfully!"); // Success message
      } else {
        alert("There was an issue with your submission. Please try again.");
      }

      toggleQuoteRequest(); // Close the popup after submission
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again later.");
    }
  };

  const steps = [
    {
      question: 'What type of website do you want?',
      options: ['Business', 'E-Commerce/ Online Store', 'Personal website', 'Other'],
      setter: setWebsiteType,
    },
    {
      question: 'Which option best represents your needs for web design?',
      options: ['Create a New Website', 'Redesign Your Website', 'Other'],
      setter: setNeeds,
    },
    {
      question: 'What is your budget?',
      options: ['$500 - $1,000', '$1,000 - $3,000', '$3,000 - $6,000', '$6,000 - $12,000', 'Over $12,000'],
      setter: setBudget,
    },
    {
      question: 'When do you want your project done?',
      options: ['1 Month', '2 Months', '3 Months', '4 Months', '5 Months', '6 Months or More'],
      setter: setTimeline,
    },
    {
      question: 'Please enter your contact information',
      isContactForm: true,
    },
  ];

  return (
    <div className="quote-request-popup">
      <button className="close-button" onClick={toggleQuoteRequest}>&times;</button>
      <h2>Request Free Quote</h2>
      {step < steps.length ? (
        <form onSubmit={handleNextStep}>
          <h3>{steps[step].question}</h3>
          {steps[step].isContactForm ? (
            <>
              <input
                type="text"
                placeholder="Full Name"
                value={contactInfo.fullName}
                onChange={(e) => setContactInfo({ ...contactInfo, fullName: e.target.value })}
                required
              />
              <input
                type="email"
                placeholder="Email"
                value={contactInfo.email}
                onChange={(e) => setContactInfo({ ...contactInfo, email: e.target.value })}
                required
              />
              <input
                type="tel"
                placeholder="Mobile Number"
                value={contactInfo.mobile}
                onChange={(e) => setContactInfo({ ...contactInfo, mobile: e.target.value })}
                required
              />
            </>
          ) : (
            <div className="radio-options-container">
              {steps[step].options.map((option) => (
                <label key={option} className="radio-option">
                  <input
                    type="radio"
                    name={steps[step].question}
                    value={option}
                    onChange={() => steps[step].setter(option)}
                    required
                  />
                  {option}
                </label>
              ))}
            </div>
          )}
          <div className="button-container">
            {step > 0 && (
              <button type="button" onClick={handleBackStep}>
                Back
              </button>
            )}
            <button type="submit">{step === steps.length - 1 ? 'Submit' : 'Next'}</button>
          </div>
        </form>
      ) : (
        <div>
          <h3>Thank you for your request!</h3>
          <p>We will get back to you shortly.</p>
          <button className='close-button' onClick={toggleQuoteRequest}>Close</button>
        </div>
      )}
    </div>
  );
};

export default QuoteRequest;
