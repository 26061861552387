import React, { useState } from 'react';
import '../Contact.css';

const Contact = ({ toggleContactPopup }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });

  const [error, setError] = useState(null); // State to manage error messages

  // Handle form input change
  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null); // Reset any existing error

    try {
      // Sending data to the PHP backend on Hostinger
      const response = await fetch('https://nestechsolutions.barkatkamran.com/contact.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert("Form submitted successfully!"); // Success message
        // Reset form data
        setFormData({
          name: '',
          email: '',
          phone: '',
          subject: '',
          message: ''
        });
      } else {
        const errorData = await response.json();
        console.error('Error response:', errorData);
        setError(`Failed to submit the form: ${errorData.message || 'Unknown error'}`);
      }

      toggleContactPopup(); // Close the form after submission
    } catch (error) {
      console.error("Error:", error);
      setError('An error occurred. Please try again later.');
    }
  };

  return (
    <div className="contact-popup" onClick={toggleContactPopup}>
      <div className="contact-content" onClick={(e) => e.stopPropagation()}>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <span className="close-btn" onClick={toggleContactPopup}>
          &times;
        </span>
        <h2>Let's Talk!</h2>
        <p>Please fill this form, and we'll get back to you through email!</p>

        {/* Display error message */}
        {error && <div className="error-message">{error}</div>}

        <form onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Name *"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email *"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <input
            type="number"
            name="phone"
            placeholder="Phone Number *"
            value={formData.phone}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="subject"
            placeholder="Subject *"
            value={formData.subject}
            onChange={handleChange}
            required
          />
          <textarea
            name="message"
            placeholder="Message *"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
          <button type="submit">Send Request</button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
